.ad-container {
    padding: 20px;
    a {
        width: 312px;
        border: 10px solid $cwl-purple;
        img {
            margin: 0;
        }
    }
}

@media #{$medium-and-up} {
    .ad-container {
        a {
            width: 100%;
            max-height: 300px;
        }
    }
}

@media #{$large-and-up} {
    .ad-container {
        a {
            width: 312px;
            max-height: initial;
            border: 5px solid $cwl-purple-darker;
        }
    }
}
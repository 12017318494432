@import "_normalize";
@import "_grid";
@import "_general";
@import "_buttons";
@import "_assets";
@import "_fonts";
@import "layout";
@import "navigation";
@import "card";
@import "screenshot-of-the-day";
@import "ad-container";
@import "footer";

@import "large-and-up";
@import "medium-and-down";

html {
  min-height: 100%;
}

body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.cookie-bar {
  background: #111 !important;
  position: relative !important;
  height: 80px;
  line-height: 80px;
  div {
    margin: 0 !important;
  }
}

.browser-default {
  font-family: "Open Sans", sans-serif;
  cursor: pointer;
  color: white;
  border: none;
  border-bottom: 1px solid $cwl-gold;
  margin-bottom: 20px;
  background: linear-gradient(45deg, $cwl-purple-dark 50%, white 50%),
    linear-gradient(135deg, white 50%, $cwl-purple-dark 50%);
  background-color: $cwl-purple-darker;
  background-position: calc(100% - 21px) calc(1em + 2px),
    calc(100% - 16px) calc(1em + 2px), 100% 0;
  background-size: 5px 5px, 5px 5px, 2.5em 2.5em;
  background-repeat: no-repeat;
  line-height: 1.5em;
  padding: 0.2em 3.5em 0.2em 0.5em;
  border-radius: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  height: 2.6rem !important;
  font-size: 18px;
  text-transform: capitalize;

  &:hover {
    background-color: $cwl-purple-light;
  }
}
.guide-panel {
  margin-bottom: 15px;
  padding: 15px 15px 15px 15px;
}

.guide-rows {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  grid-column-gap: 30px;
  grid-row-gap: 10px;
  padding: 10px 30px;
  transition: all 0.5s ease-in-out;
  .card {
    &:hover {
      box-shadow: 2px 2px 2px rgba(134, 97, 134, 1);
    }
  }
}

.adpad {
  padding-top: 10px;
}

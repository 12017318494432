@media #{$medium-and-down} {
  /*Above the fold*/
  body {
    margin: 0;
    @include cwl-font-normal;
    font-size: $unit * 2.5;
    color: $white;
    background: url($cwl-bg-img) no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-color: $cwl-purple;
    height: 100%;
    display: flex;
    min-height: 100vh;
    flex-direction: column;
  }

  main {
    flex: 1 0 auto;
  }

  .cwl-main-background {
    background: $cwl-purple-dark;
    padding: 26px 0;
    .container {
      background: $cwl-purple-darker-rgba;
    }
  }

  .cwl-header-row {
    padding-bottom: 20px;
    h4 {
      margin: (1.55rem / 3) 0 (1.55rem / 2) 0;
      color: #ffffff;
    }
  }

  .cwl-content {
    height: 100%;
  }

  form {
    padding: 20px 60px;
    box-shadow: 2px 2px $cwl-gold;

    button,
    h5 {
      margin: 20px 0;
    }
  }

  .cwl-breadcrumb {
    background: transparent;
  }

  hr {
    border: 1px solid $cwl-purple !important;
  }

  .cwl-logo {
    line-height: 80px;
    display: block;
    max-height: 80px;
    img {
      margin: 18px 16px 0 0;
    }
  }

  /* Homepage design */
  .cwl-homepage-container {
    padding-top: 10px;
  }

  .cwl-homepage-left-container {
    width: 100%;
    display: flex;
    flex-flow: column wrap;
  }

  .cwl-homepage-right-container {
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    align-items: center;
  }

  /* Offsite-link styles*/

  .offsite-links {
    display: none;
    &-container {
      display: flex;
      justify-content: center;
      width: 312px;

      margin-left: auto;
      margin-right: auto;
    }
    &-body {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      margin: 15px 0;
    }
  }

  /* Ad styles*/
  .ad {
    &-container {
      display: flex;
      justify-content: center;
      width: 312px;
      margin-bottom: 30px;
      margin-left: auto;
      margin-right: auto;

      img {
        width: 100%;
        height: 100%;
        margin: 10px 0;
      }
    }
  }

  .cwl-guide-content {
    background-color: $cwl-purple-lighter;
    color: $white;
    padding: 10px 20px;
  }

  .cwl-content {
    margin-top: 100px;
    height: 100%;
    .cwl-splash-image-container {
      position: relative;
      overflow: hidden;

      height: $unit * 50;

      .cwl-splash-image-slide {
        -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none; /* Safari */
        -khtml-user-select: none; /* Konqueror HTML */
        -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
        user-select: none; /* Non-prefixed version, currently */

        .cwl-splash-image-excerpt {
          max-width: 72%;
          margin: $unit * 2 0 $unit * 4;
        }

        .cwl-splash-image-cta {
          display: flex;

          .cwl-button {
            margin-right: $gap;
          }
        }

        .splash-image {
          position: absolute;
          top: 0;
          right: 0;
        }
      }
    }
  }

  .cwl-cta-section {
    height: $unit * 8;
    background: $cwl-purple;
  }

  .cwl-breadcrumb-pages {
    ul {
      padding: 0;
      list-style: none;

      li {
        font-size: $cwl-font-small;
      }
    }
  }

  /* Search results */
  .cwl-search-results {
    .search-results-headline {
      margin-bottom: $unit * 2;

      font-size: $cwl-font-large;
      @include cwl-font-semi-bold;
    }

    .cwl-search-list-features {
      margin-bottom: $unit * 2;

      ul {
        margin: 0;
        padding: 0;

        list-style: none;

        li {
          margin-right: $unit * 2;

          &:last-of-type {
            margin-right: 0;
          }
        }
      }
    }

    .cwl-search-results-keywords {
      display: inline-block;

      .keyword-tag {
        margin-right: $unit * 2;
        margin-bottom: $unit * 2;
        float: left;

        color: rgba(250, 250, 250, 0.72);
        background: $cwl-purple-lighter;
        cursor: grab;
        @include noselect;

        span {
          display: inline-block;

          &.keyword {
            padding-left: $unit;
          }

          &.close-icon {
            margin-left: $unit * 2;
            padding: $unit;

            cursor: pointer;
          }
        }

        &.druid {
          color: $cwl-druid;
        }
        &.hunter {
          color: $cwl-hunter;
        }
        &.mage {
          color: $cwl-mage;
        }
        &.paladin {
          color: $cwl-paladin;
        }
        &.priest {
          color: $cwl-priest;
        }
        &.rogue {
          color: $cwl-rogue;
        }
        &.shaman {
          color: $cwl-shaman;
        }
        &.warlock {
          color: $cwl-warlock;
        }
        &.warrior {
          color: $cwl-warrior;
        }
      }
    }
  }

  /* Front page teaser feed tabs */
  .cwl-tabs {
    height: $unit * 7;

    ul {
      margin: 0;
      padding: 0;

      list-style: none;

      li {
        display: inline-flex;
        height: -webkit-fill-available;
        margin-right: 1px;
        padding: $unit * 2;
        align-items: center;
        float: left;

        opacity: 0.5;

        @include cwl-font-semi-bold;
        text-transform: uppercase;
        font-size: $cwl-font-large;

        background: $cwl-purple-lighter;
        cursor: pointer;

        &:last-of-type {
          margin-right: 0;
        }

        &.active {
          opacity: 1;
        }
      }
    }
  }

  #nav-mobile li a {
    span.avatar {
      display: block;
      width: $unit * 8;
      height: $unit * 8;
      border: 2px solid $cwl-purple-lighter;
      border-radius: 50%;
      margin: 16px;
    }
  }

  .cwl-results-row {
    margin-top: 20px;
  }

  .cwl-map-grid {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .cwl-map-presenter {
    margin: auto;
    position: relative;
    transition: all 0.3s ease-in-out;
  }

  .cwl-map-presenter img {
    border: 2px solid #fff;
  }

  .cwl-map-presenter div,
  .cwl-map-presenter img {
    transition: all 0.3s ease-in-out;
  }

  .tooltip {
    position: absolute;
    color: #fff;
    padding: 10px;
    background: rgba(0, 0, 0, 0.8);
    transform: translate3d(-50%, -50%, 0);
    border-radius: 5px;
    pointer-events: none;
    z-index: 1000;
  }
  .switch label {
    font-size: 18px;
    color: $white;
  }
  .cwl-atlas-nav {
    background-color: $cwl-purple;
  }
  // .sidenav {
  //   li:not(.logo-list-item) {
  //     padding-left: 20px;
  //   }
  //   a {
  //     @include cwl-font-semi-bold;
  //     &:hover {
  //       color: $cwl-gold;
  //     }
  //   }
  //   h5 {
  //     color: $cwl-grey;
  //   }
  //   .logo-link {
  //     line-height: 80px;
  //     height: 80px;
  //     display: relative;
  //     padding: 20px !important;
  //     margin-bottom: 20px;
  //     background-color: $cwl-purple-darker;
  //   }
  // }
}

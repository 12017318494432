@import "./../../css/materialize";

header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  height: 120px;
}
.cwl-headbar {
  width: 100%;
  background-color: $cwl-purple-dark;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.cwl-game-nav {
  line-height: 80px;
  height: 80px;
}

.sticky-pin {
  position: sticky;
  top: 0;
}

.carousel {
  &.carousel-slider {
    top: 0;
    left: 0;

    .carousel-fixed-item {
      &.with-indicators {
        bottom: 68px;
      }

      position: absolute;
      left: 0;
      right: 0;
      bottom: 20px;
      z-index: 1;
    }

    .carousel-item {
      width: 100%;
      height: 100%;
      min-height: $carousel-height;
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  overflow: hidden;
  width: 100%;
  height: $carousel-height;

  .carousel-item {
    visibility: hidden;
    width: $carousel-item-width;
    height: $carousel-item-height;
    position: absolute;
    top: 0;
    left: 0;

    & > img {
      width: 100%;
    }
  }

  .indicators {
    text-align: left;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0;
    position: absolute;

    .indicator-item {
      &.active {
        background-color: #fff;
      }

      display: inline-block;
      position: relative;
      cursor: pointer;
      height: 16px;
      width: 16px;
      margin: 12px 4px;
      background-color: rgba(255, 255, 255, 0.5);

      transition: background-color 0.3s;
      border-radius: 50%;
    }
  }

  // Materialbox compatibility
  &.scrolling .carousel-item .materialboxed,
  .carousel-item:not(.active) .materialboxed {
    pointer-events: none;
  }
}

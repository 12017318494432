/* ScreenShot of the day styles*/
.screenshot {
  &-container {
    display: flex;
    justify-content: center;
  }
  &-body {
    width: 100%;
    border-radius: 5px;
    padding: 15px;
    margin-left: 15px;
  }
  &-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    h5 {
      margin: 0;
      padding: 0 0 10px 0;
      color: $cwl-gold;
    }
  }
  &-title {
    position: absolute;
    bottom: 42px;
    right: 6px;
    font-size: 16px;
    text-shadow: 2px 1px #333;
  }

  &-card {
    height: 188px;
    button {
      background-color: transparent;
      border: none !important;
      padding: 0 !important;
      width: 100%;
      height: 100%;
    }
    img {
      cursor: pointer;
      width: 100%;
      height: 100%;
    }
  }
  &-modal {
    background-color: #111 !important;
    padding: 4px !important;
    img {
      width: 100%;
    }

    &-label {
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      background-color: $black;
      padding: 0 25px;
      p {
        margin: 5px 0 13px 0;
        color: $cwl-grey;
      }
      span {
        color: $cwl-gold;
      }
    }
  }
}

@media #{$medium-and-up} {
  .screenshot {
    &-body {
      margin-left: 10px;
    }
  }
}

@media #{$large-and-up} {
  .screenshot {
    &-body {
      margin-left: 20px;
    }
  }
}

@import "_color";
@import "_fonts";

.cwl-button {
  a {
    display: inline-block;
    padding: 13.5px $unit * 2;

    text-transform: uppercase;
    text-decoration: none;
    @include cwl-font-semi-bold;

    color: $white;
  }

  &.main-button {
    background: $cwl-gold;
  }

  &.secondary-button {
    background: $cwl-purple-lighter;
  }
}

@import "../../../css/color";

.nav-home {
  height: 150px;
  background: #111;
}
.homesplash {
  margin-bottom: 40px;
  margin-bottom: 0 !important;
}
.home-splash-header {
  background: #111;
}
.home-row {
  display: flex;
}
.home-col {
  flex: 50%;
}
.home-panel {
  background: $cwl-purple-darker-rgba;
  border: 1px solid #222;
  margin-bottom: 15px;
  padding: 0 15px 15px 15px;
}
.home-links a {
  margin-right: 10px;
  color: #eeeeee;
  &:hover {
    color: $cwl-gold;
  }
}
.lander {
  height: 100% !important;

  &.tbc {
    background: url("../../../img/illidan.jpg") no-repeat center;
    background-size: cover;
    filter: contrast(95%);
    &:hover {
      opacity: 1;
      -webkit-filter: none; /* Safari 6.0 - 9.0 */
      filter: contrast(110%);
    }
  }
  &.classic {
    background: url("../../../img/rag.jpg") no-repeat center;
    background-size: cover;
    filter: contrast(95%);
    &:hover {
      opacity: 1;
      -webkit-filter: none; /* Safari 6.0 - 9.0 */
      filter: contrast(110%);
    }
  }
}
.desc {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  .title {
    text-transform: uppercase;
    font-size: 55px;
    font-weight: bold;
    margin: auto;
    line-height: 1;
    color: #fff;
    text-shadow: 2px 2px #000;
    width: 80%;
    &:hover {
      color: $cwl-gold;
    }
  }
  .author {
    font-size: 20px;
    margin: 15px auto;
    color: #eee;
    width: 80%;
    text-shadow: 1px 1px #000;
    a {
      background: rgba(0, 0, 0, 0.5);
      padding: 3px 5px;
      border-radius: 5px;
      &:hover {
        color: $cwl-gold;
        background: rgba(0, 0, 0, 0.8);
      }
    }
  }
}
.banner {
  height: 500px;
  overflow: hidden;
  position: relative;
}

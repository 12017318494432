.page-footer {
  border-top: 1px solid $cwl-purple;
  padding-top: 0 !important;
  padding-top: 20px;
  background: rgba(0, 0, 0, 0.3);
}

.footer {
  &-links {
    text-align: center;
    ul {
      margin-left: 10px;
    }
    li {
      margin: 0;
    }
  }

  &-logo {
    img {
      margin-top: 10px;
    }
  }

  &-donate {
    border-top: 1px solid $cwl-gold;
    border-bottom: 1px solid $cwl-gold;
    padding: 20px 10px !important;
    p {
      text-align: center;
      margin-top: 0;
      color: $cwl-gold;
    }
    > div {
      display: flex;
      justify-content: center;
    }
  }

  &-social {
    p {
      text-align: center;
    }

    ul {
      display: flex;
      justify-content: center;
      margin-top: 20px;
      li:last-child {
        margin: 0;
      }
    }
  }

  &-copyright {
    font-size: 1rem;
  }
}

@media #{$medium-and-up} {
  .footer {
    &-main {
      border-bottom: 0;
    }
    &-logo {
      img {
        display: block;
        margin: 30px auto 0;
        width: 50%;
      }
    }
    &-donate {
      border: none;
      padding: 0 !important;
      p {
        color: white;
      }
    }
    &-copyright {
      background: $cwl-purple-rgba;
    }
  }
}

@media #{$large-and-up} {
  .footer {
    &-main {
      border-bottom: 0px !important;
    }
    &-logo {
      padding-left: 0 !important;
      img {
        width: 100%;
        padding: 20px 20px 20px 0;
      }
      ul {
        margin-left: 0;
        text-align: left;
      }
    }
    &-donate {
      p {
        text-align: left;
      }

      > div {
        justify-content: flex-start;
      }
    }
    &-social {
      p {
        text-align: left;
      }
      ul {
        justify-content: flex-start;
      }
    }
  }
}

@media #{$extra-large-and-up} {
  .footer {
    &-logo {
      img {
        width: 70%;
        margin-left: 0;
        padding: 0;
      }
    }
  }
}

/*Grid*/
$unit: 6px;

$col: 80px;
$gap: $unit * 2;

.gap {
  margin-right: $gap;
  margin-bottom: $unit;
}

.col {
  width: $col;
}
.col-2 {
  width: $col * 2 + $gap;
  margin-right: $gap;
}
.col-3 {
  width: $col * 3 + $gap;
  margin-right: $gap;
}

.col-4 {
  width: $col * 4 + $gap * 3;
}

.col-5 {
  width: $col * 5 + $gap * 3;
  margin-right: $gap;
}

.col-6 {
  width: $col * 6 + $gap * 4;
  margin-right: $gap;
}

.col-7 {
  width: $col * 7 + $gap * 6;
}
.col-8 {
  width: $col * 8 + $gap * 6;
  margin-right: $gap;
}

.col-9 {
  width: $col * 9 + $gap * 8;
}
.col-10 {
  width: $col * 10 + $gap * 9;
}
.col-11 {
  width: $col * 11 + $gap * 10;
}
.col-12 {
  width: $col * 12 + $gap * 11;
}

.grid-center {
  margin: 0 auto;
}

@import "_color";

.cwl-h-all {
  color: $cwl-gold;
}
.cwl-h-druid {
  color: $cwl-druid !important;
}
.cwl-h-hunter {
  color: $cwl-hunter;
}
.cwl-h-mage {
  color: $cwl-mage;
}
.cwl-h-paladin {
  color: $cwl-paladin;
}
.cwl-h-priest {
  color: $cwl-priest;
}
.cwl-h-rogue {
  color: $cwl-rogue;
}
.cwl-h-shaman {
  color: $cwl-shaman;
}
.cwl-h-warrior {
  color: $cwl-warrior;
}
.cwl-h-warlock {
  color: $cwl-warlock;
}

.cwl-horde {
  color: $cwl-horde !important;
}
.cwl-alliance {
  color: $cwl-alliance !important;
}

.cwl-h-nightelf,
.cwl-h-human,
.cwl-h-gnome,
.cwl-h-dwarf {
  color: blue;
}
.cwl-h-orc,
.cwl-h-troll,
.cwl-h-tauren,
.cwl-h-undead {
  color: red;
}

$icon-width-sm: 16px;
$icon-height-sm: 16px;

@mixin marker-sm-svg {
  display: block;
  font-size: 20px;
  height: 32px !important;
  width: 50px !important;
  background-position: 10% 50%;
  background-size: 23px;
  background-color: rgba(37, 20, 37, 0.7);
  padding: 1px 0 0 28px;
  border-radius: 0 20px 20px 20px;
}

.cwl-sm-loot {
  background: url("../img/icons/leveling/loot.svg") no-repeat;
  @include marker-sm-svg;
  background-position: 25% 50%;
  background-size: 20px;
}
.cwl-sm-quest-start {
  background: url("../img/icons/leveling/quest-start.svg") no-repeat;
  @include marker-sm-svg;
}
.cwl-sm-spiritres {
  background: url("../img/icons/leveling/spiritres.svg") no-repeat;
  @include marker-sm-svg;
}
.cwl-sm-quest-end {
  background: url("../img/icons/leveling/quest-end.svg") no-repeat;
  @include marker-sm-svg;
}
.cwl-sm-train {
  background: url("../img/icons/leveling/train.svg") no-repeat;
  @include marker-sm-svg;
  background-position: 25% 50%;
  background-size: 20px;
}
.cwl-sm-repair {
  background: url("../img/icons/leveling/repair.svg") no-repeat;
  @include marker-sm-svg;
}
.cwl-sm-progress-objective {
  background: url("../img/icons/leveling/combat.svg") no-repeat;
  @include marker-sm-svg;
  background-position: 25% 50%;
  background-size: 20px;
}
.cwl-sm-hearth {
  background: url("../img/icons/leveling/hearth.svg") no-repeat;
  @include marker-sm-svg;
}
.cwl-sm-loot {
  background: url("../img/icons/leveling/loot.svg") no-repeat;
  @include marker-sm-svg;
}
.cwl-sm-fly {
  background: url("../img/icons/leveling/flightmaster.svg") no-repeat;
  @include marker-sm-svg;
}
.cwl-sm-die {
  background: url("../img/icons/leveling/die.svg") no-repeat;
  @include marker-sm-svg;
}
.cwl-sm-buy {
  background: url("../img/icons/leveling/buy.svg") no-repeat;
  @include marker-sm-svg;
}
.cwl-sm-save {
  background: url("../img/icons/leveling/save.svg") no-repeat;
  @include marker-sm-svg;
  background-position: 25% 50%;
  background-size: 20px;
}
.cwl-sm-mark {
  background: url("../img/icons/leveling/gohere.svg") no-repeat;
  @include marker-sm-svg;
  background-position: 25% 50%;
  background-size: 20px;
}
.cwl-sm-go {
  background: url("../img/icons/leveling/walk.svg") no-repeat;
  @include marker-sm-svg;
}
.cwl-sm-dungeon {
  background: url("../img/icons/pve3.png") no-repeat;
  @include marker-sm-svg;
  background-position: 20% 50%;
  background-size: 24px;
}
.cwl-sm-flightpath {
  background: url("../img/icons/leveling/getflightpath.svg") no-repeat;
  @include marker-sm-svg;
  background-position: 20% 50%;
  background-size: 24px;
}
.cwl-sm-cc {
  background: url("../img/icons/leveling/cc.svg") no-repeat;
  @include marker-sm-svg;
  background-position: 20% 50%;
  background-size: 24px;
}
.cwl-sm-clear {
  background: url("../img/icons/leveling/clear.svg") no-repeat;
  @include marker-sm-svg;
  background-position: 20% 50%;
  background-size: 24px;
}
.cwl-sm-enter {
  background: url("../img/icons/leveling/enter.svg") no-repeat;
  @include marker-sm-svg;
  background-position: 20% 50%;
  background-size: 24px;
}
.cwl-sm-pull {
  background: url("../img/icons/leveling/pull.svg") no-repeat;
  @include marker-sm-svg;
  background-position: 20% 50%;
  background-size: 24px;
}
.cwl-sm-beware {
  background: url("../img/icons/leveling/beware.svg") no-repeat;
  @include marker-sm-svg;
  background-position: 20% 50%;
  background-size: 24px;
}
.cwl-sm-interrupt {
  background: url("../img/icons/leveling/interrupt.svg") no-repeat;
  @include marker-sm-svg;
  background-position: 20% 50%;
  background-size: 24px;
}

$icon-width: 32px;
$icon-height: 32px;

.cwl-spacer {
  float: left;
  width: $icon-width;
  height: $icon-width;
}
@mixin marker-svg {
  display: block;
  height: 32px !important;
  width: 32px !important;
  background-size: 32px;
}

.cwl-loot {
  background: url("../img/icons/leveling/loot.svg") no-repeat;
  @include marker-svg;
  background-position: 25% 50%;
  background-size: 26px;
}
.cwl-quest-start {
  background: url("../img/icons/leveling/quest-start.svg") no-repeat;
  @include marker-svg;
}
.cwl-spiritres {
  background: url("../img/icons/leveling/spiritres.svg") no-repeat;
  @include marker-svg;
}
.cwl-quest-end {
  background: url("../img/icons/leveling/quest-end.svg") no-repeat;
  @include marker-svg;
}
.cwl-train {
  background: url("../img/icons/leveling/train.svg") no-repeat;
  @include marker-svg;
  background-position: 25% 50%;
  background-size: 25px;
}
.cwl-repair {
  background: url("../img/icons/leveling/repair.svg") no-repeat;
  @include marker-svg;
}
.cwl-progress-objective {
  background: url("../img/icons/leveling/combat.svg") no-repeat;
  @include marker-svg;
}
.cwl-hearth {
  background: url("../img/icons/leveling/hearth.svg") no-repeat;
  @include marker-svg;
}
.cwl-loot {
  background: url("../img/icons/leveling/loot.svg") no-repeat;
  @include marker-svg;
}
.cwl-fly {
  background: url("../img/icons/leveling/flightmaster.svg") no-repeat;
  @include marker-svg;
}
.cwl-die {
  background: url("../img/icons/leveling/die.svg") no-repeat;
  @include marker-svg;
}
.cwl-buy {
  background: url("../img/icons/leveling/buy.svg") no-repeat;
  @include marker-svg;
}
.cwl-save {
  background: url("../img/icons/leveling/save.svg") no-repeat;
  @include marker-svg;
  background-position: 25% 50%;
}
.cwl-mark {
  background: url("../img/icons/leveling/gohere.svg") no-repeat;
  @include marker-svg;
}
.cwl-go {
  background: url("../img/icons/leveling/walk.svg") no-repeat;
  @include marker-svg;
}
.cwl-dungeon {
  background: url("../img/icons/pve3.png") no-repeat;
  @include marker-svg;
}
.cwl-flightpath {
  background: url("../img/icons/leveling/getflightpath.svg") no-repeat;
  @include marker-svg;
}
.cwl-cc {
  background: url("../img/icons/leveling/cc.svg") no-repeat;
  @include marker-svg;
}
.cwl-clear {
  background: url("../img/icons/leveling/clear.svg") no-repeat;
  @include marker-svg;
}
.cwl-enter {
  background: url("../img/icons/leveling/enter.svg") no-repeat;
  @include marker-svg;
}
.cwl-pull {
  background: url("../img/icons/leveling/pull.svg") no-repeat;
  @include marker-svg;
}
.cwl-beware {
  background: url("../img/icons/leveling/beware.svg") no-repeat;
  @include marker-svg;
}
.cwl-interrupt {
  background: url("../img/icons/leveling/interrupt.svg") no-repeat;
  @include marker-svg;
}

$icon-m-width: 45px;
$icon-m-height: 45px;

@mixin race-sprite {
  float: left;
  background: url("../img/icons/races.png") no-repeat 0 0;
  width: $icon-m-width;
  height: $icon-m-width;
}
.cwl-m-human {
  @include race-sprite;
  background-position-x: 0;
  background-position-y: 0;
}
.cwl-m-dwarf {
  @include race-sprite;
  background-position-x: -($icon-m-width);
  background-position-y: 0;
}
.cwl-m-gnome {
  @include race-sprite;
  background-position-x: -($icon-m-width * 2);
  background-position-y: 0;
}
.cwl-m-nightelf {
  @include race-sprite;
  background-position-x: -($icon-m-width * 3);
  background-position-y: 0;
}
.cwl-m-orc {
  @include race-sprite;
  background-position-x: -($icon-m-width * 3);
  background-position-y: -($icon-m-height);
}
.cwl-m-tauren {
  @include race-sprite;
  background-position-x: 0;
  background-position-y: -($icon-m-height);
}
.cwl-m-troll {
  @include race-sprite;
  background-position-x: -($icon-m-width * 2);
  background-position-y: -($icon-m-height);
}
.cwl-m-undead {
  @include race-sprite;
  background-position-x: -($icon-m-width);
  background-position-y: -($icon-m-height);
}

@mixin class-sprite {
  float: left;
  background: url("../img/icons/classes.png") no-repeat 0 0;
  width: $icon-m-width;
  height: $icon-m-width;
}
.cwl-m-warrior {
  @include class-sprite;
  background-position-x: 0;
  background-position-y: 0;
}
.cwl-m-mage {
  @include class-sprite;
  background-position-x: -($icon-m-height);
  background-position-y: 0;
}
.cwl-m-rogue {
  @include class-sprite;
  background-position-x: -($icon-m-height * 2);
  background-position-y: 0;
}
.cwl-m-druid {
  @include class-sprite;
  background-position-x: -($icon-m-height * 3);
  background-position-y: 0;
}
.cwl-m-hunter {
  @include class-sprite;
  background-position-x: 0;
  background-position-y: -($icon-m-height);
}
.cwl-m-shaman {
  @include class-sprite;
  background-position-x: -($icon-m-height);
  background-position-y: -($icon-m-height);
}
.cwl-m-priest {
  @include class-sprite;
  background-position-x: -($icon-m-height * 2);
  background-position-y: -($icon-m-height);
}
.cwl-m-warlock {
  @include class-sprite;
  background-position-x: -($icon-m-height * 3);
  background-position-y: -($icon-m-height);
}
.cwl-m-paladin {
  @include class-sprite;
  background-position-x: 0;
  background-position-y: -($icon-m-height * 2);
}

.cwl-homepage-right-container {
  > div {
    width: 100%;
  }
}

$small-screen-up: 601px !default;
$medium-screen-up: 993px !default;
$large-screen-up: 1201px !default;
$small-screen: 600px !default;
$medium-screen: 992px !default;
$large-screen: 1200px !default;

$small-and-up: "only screen and (min-width : 0px)" !default;
$medium-and-up: "only screen and (min-width : #{$small-screen-up})" !default;
$large-and-up: "only screen and (min-width : #{$medium-screen-up})" !default;
$extra-large-and-up: "only screen and (min-width : #{$large-screen-up})" !default;
$small-and-down: "only screen and (max-width : #{$small-screen})" !default;
$medium-and-down: "only screen and (max-width : #{$medium-screen})" !default;
$medium-only: "only screen and (min-width : #{$small-screen-up}) and (max-width : #{$medium-screen})" !default;

@media #{$medium-and-up} {
  .cwl-homepage-right-container {
    .live-window-parent-container,
    .screenshot-parent-container,
    .ad-parent-container {
      width: 50%;
    }
    .ad-container {
      width: 100%;
      padding: 0;
      height: 200px;
      margin-top: 20px;
    }
  }
}

@media #{$large-and-up} {
  .cwl-homepage-right-container {
    .live-window-parent-container,
    .screenshot-parent-container,
    .ad-parent-container {
      width: 100%;
    }
    .ad-container {
      height: initial;
    }
  }
}

/* Mobile View Navigation */
.sidenav {
  li:not(.logo-list-item) {
    padding: 0px 20px;
  }
  a {
    @include cwl-font-semi-bold;
    &:hover {
      color: $cwl-gold;
    }
  }
  h5 {
    color: $cwl-gold;
    border-bottom: 1px solid $cwl-gold;
  }
  .logo-link {
    line-height: 80px;
    height: 80px;
    display: relative;
    padding: 20px;
    margin-bottom: 20px;
    background-color: $cwl-purple-darker;
  }
  /* First Item of "Navigation" menu */
  .cwl-header-navigation-list-item:nth-of-type(3) {
    margin-top: 20px;
  }

  .cwl-header-navigation-list-item a {
    border-bottom: 1px solid $cwl-purple-darker;
  }

  /* Last link of "Navigation" menu */
  .cwl-header-navigation-list-item:nth-of-type(11) a {
    border-bottom: none;
    margin-bottom: 20px;
  }

  .logo-list-item {
    border-bottom: none;
  }
}

@media #{$large-and-up} {
  nav ul li {
    padding: 0;
  }

  .cwl-active-navigation-link {
    color: $cwl-gold;
    text-shadow: #111 1px 1px 2px;
  }

  #nav-mobile {
    /* Login Link */
    li a {
      text-shadow: #6a410c 1px 1px 2px;
    }
  }
}

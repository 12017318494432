@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700&display=swap");

@mixin cwl-font-normal {
  font-family: "Open Sans", sans-serif;
}
@mixin cwl-font-semi-bold {
  font-family: "Open Sans", sans-serif;
}

/* Font sizes */
$cwl-font-small: 13px;
$cwl-font-normal: 16px;
$cwl-font-large: 20px;

h1,
h2,
h3,
h4,
h5 {
  text-transform: uppercase;
  @include cwl-font-semi-bold;
}

h1,
h2,
h3,
h4 {
  color: $cwl-gold;
}

a {
  color: #80a7ff;
}
